import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from './components/HomePage';
import ProductsPage from './components/ProductsPage';
import NewsPage from './components/NewsPage';
import SupportPage from './components/SupportPage';
import ScrollToTop from './components/ScrollToTop';
import './index.css'; // 假设所有基本样式都在这个文件中定义

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />  // 确保它在路由器内部
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/products" component={ProductsPage} />
        <Route path="/news" component={NewsPage} />
        <Route path="/support" component={SupportPage} />
        {/* 可以添加更多路由来处理其他页面 */}
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
