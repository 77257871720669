
import React from 'react';
const scrollToSection = (id) => {
  document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
}

const CompanyInfoSection = () => {
  return (
    <div id='CompanyInfoSection' style={{
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      height: '80vh', 
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      width: '100vw',  // 确保宽度为视口宽度
      overflow: 'hidden',  // 避免溢出
    }}>
      <img src="/img/arrow-down.gif" alt="向下箭头" onClick={() => scrollToSection('PlatformSection')}  style={{ position: 'absolute', top: '90px', cursor: 'pointer', width: '40px',transform: 'rotate(180deg)'}}/>
      <h1>公司介绍</h1>
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: '20px'
      }}>
        <img src="/img/logobig-white.png" alt="公司大Logo" style={{ width: '30%', height: 'auto' }} />
        <div style={{
          width: '40%',
          textAlign: 'justify', // 文本两端对齐
          padding: '0 20px' // 添加内边距以避免文字紧贴到容器边缘
        }}>
          <p>艾米森（广州）计算机科技有限公司，始创于2023年，是一家专注于大语言模型技术应用的领先企业。我们致力于研发和应用先进的人工智能技术，特别是大语言模型的落地应用，为各行各业提供增效方案。</p>
          <p>我们的团队由一群富有经验和充满激情的专业人士组成，他们对人工智能技术的掌握和热爱，使我们能够为客户提供高质量的服务。</p>
          <p>我们的宗旨是将前沿的AI技术与实际需求相结合，帮助企业和个人通过高效的决策支持，提升业务效率和工作效率。我们坚信，人工智能是未来的关键驱动力，我们也将致力于推动这一领域的发展，与全球的合作伙伴共享成果。</p>
          <p>艾智云是艾米森旗下专注于物联网设备的品牌，致力于打造基于4G和人工智能技术的物联网设备，服务于智慧工作和生活。</p>
        </div>
        <div style={{ width: '20%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src="/img/qr1.png" alt="二维码1" style={{ marginBottom: '10px', maxWidth: '60%' }} />
          <img src="/img/qr2.png" alt="二维码2" style={{ marginBottom: '10px', maxWidth: '60%' }} />
        </div>
      </div>
    </div>
  );
};

export default CompanyInfoSection;
