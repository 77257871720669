import React from 'react';

const Footer = () => {
  return (
    <footer style={{
      backgroundColor: '#1a1a1a',
      color: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px 50px',
      fontSize: '16px',
      width: '100%', // 确保宽度为视口宽度
      boxSizing: 'border-box'
    }}>
      <div>
        <h3 style={{ color: '#f0f0f0' }}>AI Mission</h3>
        <p>AI定义的物联网方案供应商</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <div>
          <h4>我们的产品</h4>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li><a href="/products#product1" style={{ textDecoration: 'none', color: 'inherit' }}>艾智云AI录音笔4G版</a></li>
            <li><a href="/products#product2" style={{ textDecoration: 'none', color: 'inherit' }}>艾智云AI扫点笔4G版</a></li>
            <li>艾智云AI物联网平台</li>
          </ul>
        </div>
        <div>
          <h4>关于</h4>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li>我们的邮箱：<a href='mailto:support@ai-mission.com' style={{ textDecoration: 'none', color: 'inherit' }}>support@ai-mission.com</a></li>
          </ul>
        </div>
        <div>
          <h4>资源</h4>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li>AI冲锋号</li>
            <li>AI Mission</li>
            <li>艾智云助手</li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
