import React, { useEffect} from 'react';
import { Link } from 'react-router-dom';



const Navbar = ({title}) => {

  useEffect(() => {
    document.title = title;  // 使用 useEffect 钩子更新文档标题
  }, [title]);  // 依赖列表中添加 title，只有当 title 变化时才重新运行

  return (
    <nav style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#333',
        color: 'white',
        padding: '10px 20px',
        position: 'fixed',  // 固定在视口顶部
        top: 0,             // 从视口顶部开始
        right: 0,
        left: 0,
        // width: '100%',      // 宽度为100%
        zIndex: 1000        // 确保导航栏在最前面
    }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src="/img/logo.png" alt="公司Logo" style={{ marginRight: 10, height: '50px' }} />
        <div><h1>AI定义的物联网方案供应商</h1></div>
      </div>
      <ul style={{ listStyle: 'none', display: 'flex', margin: 0 }}>
        <li style={{ marginLeft: '20px' }}><Link to="/" style={{ color: 'white', textDecoration: 'none' }}>主页</Link></li>
        <li style={{ marginLeft: '20px' }}><Link to="/products" style={{ color: 'white', textDecoration: 'none' }}>艾智云产品</Link></li>
        <li style={{ marginLeft: '20px' }}><a href="https://item.jd.com/10103813976860.html" style={{ color: 'white', textDecoration: 'none' }}>产品在线购买</a></li>
        <li style={{ marginLeft: '20px' }}><a href="https://chat.ai-mission.com/aizhicloud" style={{ color: 'white', textDecoration: 'none' }}>在线体验</a></li>
        <li style={{ marginLeft: '20px' }}><Link to="/support" style={{ color: 'white', textDecoration: 'none' }}>产品支持和下载</Link></li>
        <li style={{ marginLeft: '20px' }}><Link to="/news" style={{ color: 'white', textDecoration: 'none' }}>AI资讯</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
