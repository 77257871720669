import React from 'react';
import Navbar from './Navbar';
import MainBanner from './MainBanner';
import ProductSection from './ProductSection';
import PlatformSection from './PlatformSection';
import CompanyInfoSection from './CompanyInfoSection';
import Footer from './Footer';

const HomePage = () => {
  return (
    <div>
      <Navbar title="AI Mission - AI定义的物联网解决方案提供商" />
      <MainBanner />
      <ProductSection />
      <PlatformSection />
      <CompanyInfoSection />
      <Footer />
    </div>
  );
};

export default HomePage;
