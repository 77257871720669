import React,{useEffect} from 'react';
import Navbar from './Navbar';
import ProductDetails from './ProductDetails';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';


const ProductsPage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]); // 依赖于 location 对象

  return (
    <div>
      <Navbar title="AI Mission - 我们的产品" />
      {/* 增加上方内边距避免内容被导航栏遮挡 */}
      <div style={{ paddingTop: '70px' }}>
        <ProductDetails
          id='product1'
          productName="艾智云AI录音笔4G版"
          features={[
            { title: '产品介绍', text: '在快节奏的信息时代，4G版卡片式录音笔，它不仅符合现代人的携带需求，更通过尖端技术提升了工作与学习的效率，是现代技术与创新设计的完美结合，旨在通过智能化的功能提升您生活的每一个细节。', image: '/img/product1.png' ,position:'left'},
            { title: '产品特点', text: 'AI录音笔提供即时录音上传、云端智能撰写、大模型纠错、各种文档生成、多端App管理以及支持各种大模型，外形是卡片式设计，时尚便携', image: '/img/features-recorder.png',position:'right' },
            { title: '产品场景', text: 'AI录音笔4G版，随时随地访问网络，借助APP，通过多种主流大语言模型，实现对不同的应用场景实现高效的支撑。不管你是律师、学生、老板、医生，能够满足各种实时记录的要求，并且生成所需的文档和记录。', image: '/img/usecase-recorder.png' ,position:'left'},
            { title: '产品故事', text: '陈先生是一位忙碌的律师，每天都要处理大量的案件资料和法庭记录。自从使用了这款录音笔，他在法庭上的工作变得轻松许多。录音笔不仅可以实时录下庭审过程，还能通过云端即时转写成文字材料。案件结束后，陈先生通过APP迅速查看和整理法庭对话，确保每一个细节都精确无误。这不仅提高了他工作的效率，也让他在案件处理上更加得心应手.\n\n李小姐是一名记者，她的工作需要迅速准确地转达新闻事件。使用这款录音笔后，她在采访现场可以即时记录采访内容，并且实时通过4G将语音上传转写为文字。这样一来，她能够在最短的时间内，将最新的新闻动态以文字形式快速传递给编辑部，大大提升了报道的时效性和准确性。\n\n赵经理负责管理一个快节奏的项目团队。每周的项目会议都至关重要，记录准确的会议内容对于团队的进展至关重要。自从团队开始使用这款录音笔，会议的效率大大提高。录音笔实时记录会议内容并上传至云端，转写成文字。赵经理通过APP即可查看和编辑会议纪要，确保团队成员都清楚下一步的任务和目标。这项技术大幅节约了记录和整理时间，让团队更专注于实际工作。\n\n李明是一位勤奋的大学生，经常需要参加各种讲座和研讨会。他发现这款录音笔对于他的学习非常有帮助。在每次讲座结束后，他能够通过APP快速查看由录音笔实时转写的讲座内容，帮助他更好地理解和消化新知识。此外，李明还能使用此技术快速整理自己的学习笔记和课程总结，极大提升了学习效率，让他在学术上更进一步。', image: '/img/story-recorder.png',position:'right' }
          ]}
        />
        <ProductDetails
          id='product2'
          productName="艾智云AI扫点笔4G版"
          features={[
            { title: '产品介绍', text: 'AI学习词典笔4G版，集文本扫描、翻译和智能问答于一体，提供随时随地的学习助力。通过与ChatAI的直连，它能深入解析文本内容，带你探索知识的背后故事。内置4G模块让信息获取不受限制，学习更加自由。支持语音交互，让提问和答疑更加便捷。这款词典笔是小学生和中学生的理想学伴，它不仅提升了学习效率，还激发了对知识的热爱。让我们一起用AI学习词典笔4G版，轻松探索世界，享受学习的乐趣。', image: '/img/product2.png' ,position:'left'},
            { title: '产品特点', text: 'AI扫点笔是一个行走的百科全书，万能的语音助手，同时支持国内外大语言模型，可以与AI对话，无限拓展知识', image: '/img/features-scanner.png' ,position:'right'},
            { title: '产品场景', text: 'AI学习词典笔4G版，不仅是学习好帮手，更是孩子的安全守护者。随时随地与词典笔微聊，沟通无障碍；一键定位，让孩子的每一个瞬间都在父母的守望之下。选择4G版，给孩子一个全方位的呵护，也给父母一个安心的未来。', image: '/img/usecase-scanner.png',position:'left' },
            { title: '产品故事', text: '小明在阅读历史课本时，对“丝绸之路”产生了浓厚的兴趣。书中信息有限，他迫切想要了解更多。这时，他想起了手中的AI学习词典笔4G版。轻轻一扫，详细的历史背景、相关故事和丝绸之路的重要性立刻展现在他面前。小明像找到了新大陆，兴奋不已，对学习的热情更加高涨。\n\n化学课一直是小华的痛点，尤其是复杂的化学方程式让她感到困惑。面对一道难题，她决定使用AI学习词典笔4G版寻求帮助。扫一扫，化学方程式的解法和相关知识点清晰呈现，小华豁然开朗。原来，学习化学也可以这么简单有趣！AI学习词典笔4G版成了她战胜学科难题的得力助手。\n\n小明在超市发现了一种陌生的进口食品，包装上全是外文。他拿出AI学习词典笔4G版，利用内置4G网络，扫描瞬间得到了中文翻译和详细解释。原来这是一款健康又美味的零食。小明惊喜地说：“这词典笔真是太神奇了，有了它，我觉得世界变得更加精彩了！”\n\n在图书馆，小丽遇到了一个难以理解的科学术语。她轻轻按下AI学习词典笔4G版的语音按钮，清晰地读出了这个术语。不需要扫描，词典笔就通过内置的ChatAI功能迅速作出了反应，用简单易懂的语言为小丽解释了这个复杂的科学概念，并提供了相关的例子加深理解。小丽惊叹道：“原来学习可以这么简单有趣！”她发现，通过语音交互，她能更加直观快捷地获取信息，学习效率大大提高。', image: '/img/story-scanner.png' ,position:'right'}
          ]}
        />
      </div>
      <Footer />
    </div>
  );
};

export default ProductsPage;
