import React from 'react';
const scrollToSection = (id) => {
  document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
}

const ProductSection = () => {
  return (
    <div id='ProductSection' style={{ 
        position: 'relative',
        width: '100%',
        height: '100vh',
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center', 
        marginTop: '40px'
      }}>
      <img src="/img/arrow-down.gif" alt="向下箭头" onClick={() => scrollToSection('MainBanner')}  style={{ position: 'absolute', top: '90px', cursor: 'pointer', width: '40px',transform: 'rotate(180deg)'}}/>
      <h1>我们的产品</h1>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', marginTop: '20px' }}>
        <div style={{ flex: 1, textAlign: 'center', padding: '10px', display: 'flex', flexDirection: 'column' }}>
          <h2>艾智云AI录音笔4G版</h2>
          <img src="/img/product1.png" alt="产品1" style={{ maxWidth: '100%', height: '200px', marginBottom: '10px', objectFit: 'contain' }} />
          <p style={{ minHeight: '60px' }}>在快节奏的信息时代，4G版卡片式录音笔，它不仅符合现代人的携带需求，更通过尖端技术提升了工作与学习的效率，是现代技术与创新设计的完美结合，旨在通过智能化的功能提升您生活的每一个细节。
</p>
        </div>
        <div style={{ flex: 1, textAlign: 'center', padding: '10px', display: 'flex', flexDirection: 'column' }}>
          <h2>艾智云AI扫点笔4G版</h2>
          <img  src="/img/product2.png" alt="产品2" style={{ maxWidth: '100%', height: '200px', marginBottom: '10px', objectFit: 'contain' }} />
          <p style={{ minHeight: '60px' }}>GPT学习词典笔4G版，集文本扫描、翻译和智能问答于一体，提供随时随地的学习助力。通过与ChatGPT的直连，它能深入解析文本内容，带你探索知识的背后故事。内置4G模块让信息获取不受限制，学习更加自由。支持语音交互，让提问和答疑更加便捷。这款词典笔是小学生和中学生的理想学伴，它不仅提升了学习效率，还激发了对知识的热爱。让我们一起用GPT学习词典笔4G版，轻松探索世界，享受学习的乐趣。</p>
        </div>
        <div style={{ flex: 1, textAlign: 'center', padding: '10px', display: 'flex', flexDirection: 'column' }}>
          <h2>AI Flow</h2>
          <img src="/img/product3.png" alt="产品3" style={{ maxWidth: '100%', height: '200px', marginBottom: '10px', objectFit: 'contain' }} />
          <p style={{ minHeight: '60px' }}>AI Flow，是一个强大的可视化流程配置平台，可以将大模型最新的成果集成到任意工作流中，为任何平台提供AI接入能力，无论是硬件还是软件。</p>
        </div>
      </div>
      <img src="/img/arrow-down.gif" alt="向下箭头" onClick={() => scrollToSection('PlatformSection')}  style={{ position: 'absolute', bottom: '50px', cursor: 'pointer', width: '40px' }}/>
    </div>
      
  );
};

export default ProductSection;
