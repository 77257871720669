import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const NewsPage = () => {
  return (
    <div>
      <Navbar title="AI Mission - AI资讯"/>
      {/* 增加上方内边距避免内容被导航栏遮挡 */}
      <div style={{
        paddingTop: '100px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',  // 两列平分宽度
        gridTemplateRows: '1fr 1fr',     // 两行平分高度
        gap: '20px',
        height: 'calc(100vh - 100px)',    // 页面高度减去导航栏高度
        alignContent: 'center'
      }}>
        <a href="https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzkwNTUxMjU0Ng==&action=getalbum&album_id=3062119840909344772&scene=173&subscene=&sessionid=undefined&enterid=0&from_msgid=2247485138&from_itemidx=1&count=3&nolastread=1#wechat_redirect" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <div style={{ textAlign: 'center' }}>
            <img src="/img/cover.png" alt="AI信息周报" style={{ maxWidth: '100%', height: '250px', marginBottom: '10px', objectFit: 'contain' }} />
            <h3 style={{ color: 'black' }}>AI信息周报</h3>
          </div>
        </a>
        <a href="https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzkwNTUxMjU0Ng==&action=getalbum&album_id=3158458511639429125&scene=173&subscene=&sessionid=undefined&enterid=0&from_msgid=2247485119&from_itemidx=1&count=3&nolastread=1#wechat_redirect" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <div style={{ textAlign: 'center' }}>
            <img src="/img/practise.png" alt="AI每周工具实践介绍" style={{ maxWidth: '100%', height: '250px', marginBottom: '10px', objectFit: 'contain' }} />
            <h3 style={{ color: 'black' }}>AI每周工具实践介绍</h3>
          </div>
        </a>
        <a href="https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzkwNTUxMjU0Ng==&action=getalbum&album_id=3069327772130459654&scene=173&subscene=227&sessionid=1715156598&enterid=1715156602&from_msgid=2247483906&from_itemidx=1&count=3&nolastread=1#wechat_redirect" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <div style={{ textAlign: 'center' }}>
            <img src="/img/promptB.png" alt="Prompt业务场景系列文章" style={{ maxWidth: '100%', height: '250px', marginBottom: '10px', objectFit: 'contain' }} />
            <h3 style={{ color: 'black' }}>Prompt业务场景系列文章</h3>
          </div>
        </a>
        <a href="https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzkwNTUxMjU0Ng==&action=getalbum&album_id=3020151018774315010&scene=173&subscene=227&sessionid=1715156847&enterid=1715156850&from_msgid=2247483743&from_itemidx=1&count=3&nolastread=1#wechat_redirect" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <div style={{ textAlign: 'center' }}>
            <img src="/img/promptT.png" alt="Prompt技巧系列文章" style={{ maxWidth: '100%', height: '250px', marginBottom: '10px', objectFit: 'contain' }} />
            <h3 style={{ color: 'black' }}>Prompt技巧系列文章</h3>
          </div>
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default NewsPage;
