import React from 'react';

const ProductDetails = ({ id,productName, productImage, features }) => {
  return (
    <div id={id} style={{ margin: '20px', padding: '20px' }}>
      <h2>{productName}</h2><hr></hr>
      {features.map((feature, index) => (
        <div key={index} style={{ display: 'flex', margin: '10px 0' }}>
          {feature.position === 'left' && (
            <>
              <img src={feature.image} alt={feature.title} style={{ width: '50%', marginRight: '20px' }} />
              <div style={{
                display: 'flex', // 添加 display:flex
                flexDirection: 'column',
                alignItems: 'flex-start', // 水平居中对齐
                justifyContent: 'center',
                padding: '20px', // 添加一些内边距
                width: '50%', // 占满容器宽度
                marginTop: '40px',
              }}>
                <h3 style={{ textAlign: 'left' }}>{feature.title}</h3>
                <p style={{ whiteSpace: 'pre-line' }}>{feature.text}</p>
              </div>
            </>
          )}
          {feature.position === 'right' && (
            <>
              <div style={{
                display: 'flex', // 添加 display:flex
                flexDirection: 'column',
                alignItems: 'flex-start', // 水平居中对齐
                justifyContent: 'center',
                padding: '20px', // 添加一些内边距
                width: '50%', // 占满容器宽度
                marginTop: '40px',
              }}>
                <h3 style={{ textAlign: 'left' }}>{feature.title}</h3>
                <p style={{ whiteSpace: 'pre-line' }}>{feature.text}</p>
              </div>
              <img src={feature.image} alt={feature.title} style={{ width: '50%', marginLeft: '20px' }} />
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProductDetails;
