import React from 'react';
const scrollToSection = (id) => {
  document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
}
const PlatformSection = () => {
  return (
    <div id='PlatformSection' style={{
      display: 'flex',
      position: 'relative',
      flexDirection: 'column', // 改为垂直布局
      alignItems: 'center', // 水平居中对齐
      height: '100vh', 
      justifyContent: 'center',
      padding: '20px', // 添加一些内边距
      width: '100%', // 占满容器宽度
      marginTop: '40px',
      backgroundColor: '#444', // 设置深色背景
      color: 'white', // 文字颜色设置为白色
      overflow: 'hidden',
    }}>
      <img src="/img/arrow-down.gif" alt="向下箭头" onClick={() => scrollToSection('ProductSection')}  style={{ position: 'absolute', top: '90px', cursor: 'pointer', width: '40px',transform: 'rotate(180deg)'}}/>
      <h1>艾智云物联网平台</h1> 
      <div style={{
        display: 'flex',
        width: '100%', // 水平布局占满容器宽度
        padding: '50px', // 添加一些内边距
        justifyContent: 'space-between', // 确保子元素平均分布
        flexDirection: 'column', // 改为垂直布局
        alignItems: 'center' // 垂直居中对齐
      }}>
        <img src="/img/platform.jpg" alt="平台图形" style={{ width: '85%', height: 'auto' }} />
        <div style={{ width: '85%', textAlign: 'justify', padding: '0 20px', marginTop: '20px' }}> 
          <p>我们以AI为核心，开启物联网产品的无限可能。通过不断探索人工智能的新应用场景，我们致力于用AI重新定义智能设备的功能和体验。</p>
        </div>
      </div>
      <img src="/img/arrow-down.gif" alt="向下箭头" onClick={() => scrollToSection('CompanyInfoSection')}  style={{ position: 'absolute', bottom: '50px', cursor: 'pointer', width: '40px' }}/>
    </div>
  );
};

export default PlatformSection;
