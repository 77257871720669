import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const SupportPage = () => {
  return (
    <div>
      <Navbar title="AI Mission - 软件下载"/>
      <div style={{
        paddingTop: '70px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr', // 四列平分宽度
        gap: '20px',
        padding: '20px', // 添加一些内边距
        alignItems: 'center', // 在垂直方向上居中所有项目
        minHeight: 'calc(80vh - 70px)' // 确保容器至少是视窗高度减去导航栏高度
      }}>
        <a href="/img/aizhicloud.dmg" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <div style={{ textAlign: 'center' }}>
            <img src="/img/macos.jpg" alt="MacOS" style={{ width: '100%', maxHeight: '200px', objectFit: 'contain' }} />
            <h3 style={{ color: 'black' }}>艾智云-MacOS版</h3>
          </div>
        </a>
        <a href="/img/aizhicloud.exe" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <div style={{ textAlign: 'center' }}>
            <img src="/img/win.jpg" alt="Windows版" style={{ width: '100%', maxHeight: '200px', objectFit: 'contain' }} />
            <h3 style={{ color: 'black' }}>艾智云-Windows版</h3>
          </div>
        </a>
        <a href="https://apps.apple.com/cn/app/%E8%89%BE%E6%99%BA%E4%BA%91/id6478091815" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <div style={{ textAlign: 'center' }}>
            <img src="/img/iphone.jpg" alt="iphone版" style={{ width: '100%', maxHeight: '200px', objectFit: 'contain' }} />
            <h3 style={{ color: 'black' }}>艾智云-iphone版</h3>
          </div>
        </a>
        <a href="/img/aizhicloud.apk" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <div style={{ textAlign: 'center' }}>
            <img src="/img/android.png" alt="Android版" style={{ width: '100%', maxHeight: '200px', objectFit: 'contain' }} />
            <h3 style={{ color: 'black' }}>艾智云-Android版</h3>
          </div>
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default SupportPage;
