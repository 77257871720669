import React from 'react';
const scrollToSection = (id) => {
  document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
}
const MainBanner = () => {
  return (
    <div id='MainBanner' style={{
      position: 'relative',
      height: '100vh', // 使用视口高度
      backgroundImage: 'url(/img/mainbg.jpeg)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      zIndex: 1, // 确保内容位于其他元素之上
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',  // 确保宽度为视口宽度
      overflow: 'hidden', 
    }}>

      <img src="/img/logobig.png" alt="公司大Logo" style={{ width: '28%' }} />
      <div style={{ width: '30%' }}>
        <p style={{ color: 'white' }}>我们的宗旨是将前沿的AI技术与实际需求相结合，帮助企业和个人通过高效的决策支持，提升业务效率和工作效率。我们坚信，人工智能是未来的关键驱动力，我们也将致力于推动这一领域的发展，与全球的合作伙伴共享成果。
</p>
        <button onClick={() => scrollToSection('CompanyInfoSection')}>更多</button>
      </div>

    <img src="/img/arrow-down.gif" alt="向下箭头" onClick={() => scrollToSection('ProductSection')}
        style={{ position: 'absolute', bottom: '50px', cursor: 'pointer', width: '40px' }} />
    </div>
  );
};
export default MainBanner;
