import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]); // 依赖数组中的 pathname，当它变化时触发滚动

  return null;
}

export default ScrollToTop;
